<template>
  <div class="sales-documents">
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('upload') }}</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('document')"
          label-for="document"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('document')"
            v-model="selectedSalesDocumentAttachmentType"
            id="salesDocumentAttachmentType"
            :options="filteredSalesDocumentAttachmentTypes"
            :allow-empty="false"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>

          <DropzoneUpload
            class="mt-2"
            :complete="isSalesDocumentAttachmentUploadComplete"
            :parentId="project.id"
            :parentType="selectedSalesDocumentAttachmentType"
            :uploadCallback="onUploadProjectAttachments"
            :disabled="isUploadDisabled"
            :multiUpload="true"
            v-on:dropzone-upload:reset="isSalesDocumentAttachmentUploadComplete = false"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="custom-margin-bottom">
        <legend>
          <strong>{{ $t('optionalPhotos') }}</strong>
        </legend>

        <legend>{{ $t('blueprinAttachments') }}</legend>
        <AttachmentList
          :attachments="project.blueprinAttachments"
          attachmentType="blueprinAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>{{ $t('installationLocationAttachments') }}</legend>
        <AttachmentList
          :attachments="project.installationLocationAttachments"
          attachmentType="installationLocationAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>{{ $t('salesMiscAttachments') }}</legend>
        <AttachmentList
          :attachments="project.salesMiscAttachments"
          attachmentType="salesMiscAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>
      </b-col>

      <b-col lg="6" md="6" sm="12" class="custom-margin-bottom">
        <legend>
          <strong>{{ $t('requiredPhotos') }}</strong>
        </legend>

        <legend>{{ $t('roofTileAttachments') }}</legend>
        <span class="warning-text" v-if="!requirementsValidState('roofTileAttachments')">
          {{ documentMissing }}
        </span>
        <AttachmentList
          :attachments="project.roofTileAttachments"
          attachmentType="roofTileAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>{{ $t('roofAttachments') }}</legend>
        <span class="warning-text" v-if="!requirementsValidState('roofAttachments')">
          {{ documentMissing }}
        </span>
        <AttachmentList
          :attachments="project.roofAttachments"
          attachmentType="roofAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>{{ $t('meterCabinetAttachments') }}</legend>
        <span class="warning-text" v-if="!requirementsValidState('meterCabinetAttachments')">
          {{ documentMissing }}
        </span>
        <AttachmentList
          :attachments="project.meterCabinetAttachments"
          attachmentType="meterCabinetAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>
      </b-col>

      <b-col lg="6" md="6" sm="12" class="custom-margin-bottom">
        <legend>
          <strong>{{ $t('MISC') }}</strong>
        </legend>

        <legend>{{ $t('offerSalesCallOneAttachments') }}</legend>
        <AttachmentList
          :attachments="project.offerSalesCallOneAttachments"
          attachmentType="offerSalesCallOneAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>{{ $t('offerSalesCallTwoAttachments') }}</legend>
        <AttachmentList
          :attachments="project.offerSalesCallTwoAttachments"
          attachmentType="offerSalesCallTwoAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>{{ $t('planningDdAttachments') }}</legend>
        <AttachmentList
          :attachments="project.planningDdAttachments"
          attachmentType="planningDdAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>
        <legend>{{ $t('presentationAttachments') }}</legend>
        <AttachmentList
          :attachments="project.presentationAttachments"
          attachmentType="presentationAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';

export default {
  components: {
    Multiselect,
    DropzoneUpload,
    AttachmentList,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      isSalesDocumentAttachmentUploadComplete: false,
      selectedSalesDocumentAttachmentType: null,
      filteredSalesDocumentAttachmentTypes: [],
    };
  },
  computed: {
    ...mapGetters(['getEnumValues']),
    isUploadDisabled() {
      return this.selectedSalesDocumentAttachmentType === null ? true : false;
    },
    documentMissing() {
      return 'Voraussetzung';
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues', 'uploadProjectAttachment', 'deleteProjectAttachment']),
    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
    async onUploadProjectAttachments(payload) {
      for (const file of payload.files) {
        await this.uploadProjectAttachment({
          file,
          parentId: payload.parentId,
          parentType: payload.parentType,
        });
      }
      this.isSalesDocumentAttachmentUploadComplete = true;
    },
    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },

  async mounted() {
    await this.fetchEnumValues('SalesAttachmentTypeRequired');
    this.filteredSalesDocumentAttachmentTypes = this.getEnumValues('SalesAttachmentTypeRequired');
  },
};
</script>

<style scoped>
.custom-margin-bottom {
  margin-bottom: 40px;
}
.warning-text {
  color: red;
}
</style>
