var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"mb-4",attrs:{"title":_vm.$t('overview')}},[_c('b-card-text',{staticClass:"overview-card-grid-container"},[_c('div',[_vm._v(_vm._s(_vm.$t('name')))]),_c('div',[_vm._v(_vm._s(_vm.project.customer.lastname)+", "+_vm._s(_vm.project.customer.firstname))]),_c('div',[_vm._v(_vm._s(_vm.$t('address')))]),_c('div',[_c('a',{attrs:{"href":`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            _vm.project.customer.street +
              ' ' +
              _vm.project.customer.streetNumber +
              _vm.project.customer.streetNumberSuffix +
              ', ' +
              _vm.project.customer.zip +
              ' ' +
              _vm.project.customer.city
          )}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.project.customer.street)+" "+_vm._s(_vm.project.customer.streetNumber)+" "+_vm._s(_vm.project.customer.streetNumberSuffix)+", "+_vm._s(_vm.project.customer.zip)+" "+_vm._s(_vm.project.customer.city)+" ")])]),_c('div',[_vm._v(_vm._s(_vm.$t('phone')))]),(_vm.project.customer.phone)?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.startTeamsCall(_vm.project.customer.phone)}}},[_vm._v(" "+_vm._s(_vm.project.customer.phone)+" ")])]):_vm._e(),_c('div',[_vm._v("#")]),_c('div',[_vm._v(_vm._s(_vm.projectNumber))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }