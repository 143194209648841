<template>
  <div class="sales-planning">
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('configuration') }}</strong>
        </legend>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('inverter') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 ">
              <strong>{{ InverterNominalPowerOverall }} kW</strong>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('inverter1')"
              label-for="inverter"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="invalidFeedbackNA"
              :state="requirementsValidState('inverter1')"
            >
              <multiselect
                :placeholder="$t('inverter1')"
                v-model="project.solarPlant.inverter1"
                id="inverter1"
                :options="filteredInverters"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onInverterSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">{{ inverter1Power }} kW</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('inverter2')"
              label-for="inverter2"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('inverter2')"
                v-model="project.solarPlant.inverter2"
                id="inverter"
                :options="filteredInverters"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onInverterSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 ">{{ inverter2Power }} kW</div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('solarPanel') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 ">
              <strong>{{ solarPanelsOverallPower }} kWp</strong>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('solarPanel')"
              label-for="solarPanel"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="invalidFeedbackNA"
              :state="requirementsValidState('solarPanel')"
            >
              <multiselect
                :placeholder="$t('solarPanel')"
                v-model="project.solarPlant.solarPanel"
                id="solarPanel"
                :options="filteredSolarPanels"
                track-by="number"
                v-on:search-change="onSolarPanelSearch"
                :internal-search="false"
                :show-labels="true"
                :deselectLabel="$t('deselectLabel')"
                selectLabel=""
                selectedLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">
              {{ solarPanelPower }}
              Wp
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('solarPanelCount')"
              label-for="solarPanelCount"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="invalidFeedbackNA"
              :state="requirementsValidState('solarPanelCount')"
            >
              <b-form-input
                v-model="project.solarPlant.solarPanelCount"
                type="text"
                :placeholder="$t('solarPanelCount')"
                :state="requirementsValidState('solarPanelCount')"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('battery') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('battery')"
              label-for="battery"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('battery')"
                v-model="project.solarPlant.battery"
                id="battery"
                :options="filteredBatteries"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onBatterySearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">{{ batteryCapacity }} kWh</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12"> </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1" v-if="project.solarPlant.battery">
              <span>{{ $t(project.solarPlant.battery.connectionType) }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('additionalComponents') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('energyFlowDirectionSensor_short')"
              label-for="energyFlowDirectionSensor"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('energyFlowDirectionSensor_short')"
                v-model="project.solarPlant.energyFlowDirectionSensor"
                id="energyFlowDirectionSensor"
                :options="filteredEnergyFlowFirectionSensors"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onEnergyFlowDirectionSensorSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('wallbox')"
              label-for="wallbox"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('wallbox')"
                v-model="project.solarPlant.wallbox"
                id="wallbox"
                :options="filteredWallboxes"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onWallboxSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">
              <span v-if="project.solarPlant.wallbox">{{
                project.solarPlant.wallbox.maxNetInputPower
              }}</span>
              kW
            </div></b-col
          >
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('emergencyPowerState')"
              label-for="emergencyPowerState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('emergencyPowerState')"
                v-model="project.solarPlant.emergencyPowerState"
                :options="optionsYesNo"
                :show-labels="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
                >
                <template slot="option" slot-scope="{ option }">
                  <b-badge :variant="yesNoColor(option)">
                    {{ $t(option) }}
                  </b-badge></template
                >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
      </b-col>
      <b-col lg="5" md="5" sm="12">
        <div v-if="project.onHoldState">
          <legend>
            <strong>{{ $t('PLANNING_QUERIES') }}</strong>
          </legend>

          <div class="form-grid-container-left">
            <div class="label">{{ $t('PLANNING_QUERIES') }}</div>
            <div>
              <multiselect
                :placeholder="$t('PLANNING_QUERIES')"
                v-model="project.onHoldState.onHold"
                :options="yesNo()"
                :allow-empty="false"
                :show-labels="false"
                deselectLabel
                @select="onChangeProjectOnHoldState"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <b-badge :variant="yesNoStateReversedColor(option)">
                    {{ $t(option) }}
                  </b-badge>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <b-badge :variant="yesNoStateReversedColor(option)">
                    {{ $t(option) }}
                  </b-badge>
                </template>
              </multiselect>
            </div>

            <template
              v-if="
                project.onHoldState.onHold === 'YES' ||
                  project.onHoldState.description != '' ||
                  project.onHoldState.comment != ''
              "
            >
              <div class="label">{{ $t('reason') }}</div>
              <div>
                {{ $t(project.onHoldState.reason) }}
              </div>

              <div class="label">{{ $t('description') }}</div>
              <div>
                <Textarea v-model="project.onHoldState.description" rows="3" cols="80" />
              </div>

              <div class="label">{{ $t('comment') }}</div>
              <div>
                <Textarea v-model="project.onHoldState.comment" rows="3" cols="80" />
              </div>
            </template>
          </div>
        </div>

        <legend>
          <strong>{{ $t('notes') }}</strong>
        </legend>
        <b-form-group horizontal breakpoint="md">
          <ckeditor
            :editor="editor"
            v-model="project.sales.notesPlanning"
            :config="editorConfig"
          ></ckeditor>
        </b-form-group>
      </b-col>
    </b-row>

    <Dialog
      header="Begründung erforderlich"
      :visible.sync="displayStateChangeConfirmation"
      :contentStyle="{ overflow: 'visible' }"
      :modal="true"
    >
      <div v-if="project.onHoldState">
        <div class="flex-row">
          <div>{{ $t('reason') }}</div>
          <div>
            <Dropdown
              v-model="project.onHoldState.reason"
              :options="getEnumValues('OnHoldReason')"
              :placeholder="$t('select')"
            >
              <template #option="slotProps">
                <b-badge>
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
              <template #value="slotProps">
                <b-badge>
                  {{ $t(slotProps.value) }}
                </b-badge>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="flex-row">
          <div>{{ $t('description') }}</div>
          <div>
            <Textarea v-model="project.onHoldState.description" rows="3" cols="40" />
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          class="btn btn-narrow btn-default ml-2 mb-2"
          @click="onCancelProjectOnHoldStateChangeReason"
        >
          {{ $t('cancel') }}
        </Button>
        <Button
          class="btn btn-narrow btn-inverse ml-2 mb-2"
          @click="onSaveProjectOnHoldStateChangeReason"
          :disabled="onHoldStateOkButtonDisabled"
        >
          {{ $t('ok') }}
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import CKEditor from '@ckeditor/ckeditor5-vue2';

import {
  formatSolarPanelsOverallPower,
  formatSolarPanelPower,
  formatBatteryCapacity,
} from '@/helpers/projects';
import {
  formatInverterApparentPowerOverall,
  formatInverterNominalPowerOverall,
  formatInverter1Power,
  formatInverter2Power,
} from '@/helpers/inverter';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor, yesNoStateReversedColor } from '@/helpers/colors';
import Textarea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export default {
  components: {
    Multiselect,
    Textarea,
    Dialog,
    Dropdown,
    ckeditor: CKEditor.component,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      filteredSolarPanels: [],
      filteredInverters: [],
      filteredBatteries: [],
      filteredEnergyFlowFirectionSensors: [],
      filteredWallboxes: [],
      displayStateChangeConfirmation: false,
      editor: InlineEditor,
      editorConfig: {
        allowedContent: false,
        forcePasteAsPlainText: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getSolarPanels',
      'getInverters',
      'getBatteries',
      'getEnergyFlowDirectionSensors',
      'getWallboxes',
      'getNetworkSupplyManagementStates',
      'getNetworkSupplyManagementPermanentRestrictionStates',
      'getNetworkSupplyManagementXOverStates',
      'getNetOutputStates',
      'getStorageCouplingStates',
      'getElectricMeterMountingStates',
      'getHouseFuseStates',
      'getEnumValues',
    ]),
    onHoldStateOkButtonDisabled() {
      return this.project.onHoldState && this.project.onHoldState.description !== '' ? false : true;
    },
    networkSupplyManagementPermanentRestrictionStateIsDisabled() {
      return this.project.solarPlant.networkSupplyManagementState === 'PERMANENT_RESTRICTION'
        ? false
        : true;
    },
    solarPanelsOverallPower() {
      return formatSolarPanelsOverallPower(this.project);
    },
    solarPanelPower() {
      return formatSolarPanelPower(this.project);
    },
    inverterApparentPowerOverall() {
      return formatInverterApparentPowerOverall(this.project);
    },
    InverterNominalPowerOverall() {
      return formatInverterNominalPowerOverall(this.project);
    },
    inverter1Power() {
      return formatInverter1Power(this.project);
    },
    inverter2Power() {
      return formatInverter2Power(this.project);
    },
    batteryCapacity() {
      return formatBatteryCapacity(this.project);
    },
    optionsYesNo() {
      return yesNo();
    },
    /**
     * Bei der Zählerzusammenlegung/Zählerabmeldung bleibt der erste Zähler immer bestehen. Es können nur Zähler 2 und 3 ausgewählt werden.
     */
    projectElectricMeters() {
      const electricMeters = [];
      if (
        this.project.customer.electricMeterNumber2 &&
        this.project.customer.electricMeterNumber2 !== ''
      ) {
        electricMeters.push(this.project.customer.electricMeterNumber2);
      }
      if (
        this.project.customer.electricMeterNumber3 &&
        this.project.customer.electricMeterNumber3 !== ''
      ) {
        electricMeters.push(this.project.customer.electricMeterNumber3);
      }
      return electricMeters;
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
  },
  methods: {
    ...mapActions([
      'fetchInverters',
      'fetchBatteries',
      'fetchEnergyFlowDirectionSensors',
      'fetchWallboxes',
      'fetchNetworkSupplyManagementStates',
      'fetchNetworkSupplyManagementPermanentRestrictionStates',
      'fetchNetworkSupplyManagementXOverStates',
      'fetchNetOutputStates',
      'fetchStorageCouplingStates',
      'fetchElectricMeterMountingStates',
      'fetchHouseFuseStates',
      'fetchEnumValues',
    ]),
    yesNo,
    getYesNoStateColor,
    yesNoStateReversedColor,
    /**
     * if project.onHoldState is changed to yes a reason has to be given
     * @param {*} newState
     */ onChangeProjectOnHoldState(newState) {
      console.log('onChangeProjectOnHoldState()', newState);

      if (newState === 'YES') {
        this.displayStateChangeConfirmation = true;
      }
    },
    onCancelProjectOnHoldStateChangeReason() {
      // console.log(
      //   'onCancelProjectStateChangeReason()',
      //   this.project.projectState,
      //   this.projectStateBackup
      // );
      this.project.onHoldState.onHold = 'NO';
      this.displayStateChangeConfirmation = false;
    },
    onSaveProjectOnHoldStateChangeReason() {
      // console.log('onSaveProjectStateChangeReason()', this.project.onHoldState);
      this.displayStateChangeConfirmation = false;
    },
    onNetworkSupplyManagementState(state) {
      if (state === 'FRE' || state === 'NONE') {
        this.project.solarPlant.networkSupplyManagementPermanentRestrictionState = null;
      }
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    onSolarPanelSearch(query) {
      this.filteredSolarPanels = [];
      this.getSolarPanels.map((solarPanel) => {
        if (
          solarPanel.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          solarPanel.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredSolarPanels.push(solarPanel);
        }
      });
    },
    onInverterSearch(query) {
      this.filteredInverters = [];
      this.getInverters.map((inverter) => {
        if (
          inverter.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          inverter.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredInverters.push(inverter);
        }
      });
    },
    onBatterySearch(query) {
      this.filteredBatteries = [];
      this.getBatteries.map((battery) => {
        if (
          battery.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          battery.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredBatteries.push(battery);
        }
      });
    },
    onWallboxSearch(query) {
      this.filteredWallboxes = [];
      this.getWallboxes.map((wallbox) => {
        if (
          wallbox.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          wallbox.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredWallboxes.push(wallbox);
        }
      });
    },
    onEnergyFlowDirectionSensorSearch(query) {
      this.filteredEnergyFlowFirectionSensors = [];
      this.getEnergyFlowDirectionSensors.map((energyFlowDirectionSensor) => {
        if (
          energyFlowDirectionSensor.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          energyFlowDirectionSensor.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredEnergyFlowFirectionSensors.push(energyFlowDirectionSensor);
        }
      });
    },
    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
    async setup() {
      await this.fetchInverters();
      await this.fetchBatteries();
      await this.fetchEnergyFlowDirectionSensors();
      await this.fetchWallboxes();
      await this.fetchNetworkSupplyManagementStates();
      await this.fetchNetworkSupplyManagementPermanentRestrictionStates();
      await this.fetchNetworkSupplyManagementXOverStates();
      await this.fetchNetOutputStates();
      await this.fetchStorageCouplingStates();
      await this.fetchElectricMeterMountingStates();
      await this.fetchHouseFuseStates();
      await this.fetchEnumValues('SolarPlantType');
      await this.fetchEnumValues('NetworkSupplyType');
      this.filteredSolarPanels = this.getSolarPanels;
      this.filteredInverters = this.getInverters;
      this.filteredBatteries = this.getBatteries;
      this.filteredWallboxes = this.getWallboxes;
      this.filteredEnergyFlowFirectionSensors = this.getEnergyFlowDirectionSensors;
      await this.fetchEnumValues('OnHoldReason');

      /** init onHoldState */
      if (!this.project.onHoldState) {
        this.project.onHoldState = {
          onHold: 'NO',
          reason: 'MISC',
          description: '',
          comment: '',
        };
      }
    },
  },
  async mounted() {
    await this.setup();
  },
};
</script>
<style scoped lang="scss">
.form-grid-container-left {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container-left .label {
  text-align: right;
}
</style>
