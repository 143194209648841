<template>
  <div v-if="project">
    <ArrowTimeline :items="salesPhases" :colors="colors" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ArrowTimeline from '@/components/ArrowTimeline';

export default {
  components: {
    ArrowTimeline,
  },
  props: {
    project: { type: Object },
  },
  computed: {
    ...mapGetters(['getEnumValues']),
    salesPhases() {
      const salesPhases = [];
      let stateClass = 'previous';

      this.getEnumValues('SalesPhase').map((phase) => {
        if (this.project.sales.phase === phase) {
          stateClass = 'active';
        }

        salesPhases.push({ label: this.$t(phase), class: stateClass });

        if (this.project.sales.phase === phase) {
          stateClass = 'upcoming';
        }
      });
      return salesPhases;
    },
  },
  data() {
    return {
      colors: {
        active: '#e8c547',
        upcoming: '#a3aeb7',
        previous: '#0cac64',
        warning: '#ff7d47',
      },
    };
  },
};
</script>
