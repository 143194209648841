<template>
  <div class="sales-customer">
    <b-row>
      <b-col lg="5" md="5" sm="12">
        <legend>
          <strong>Kundeninfos</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('salutation')"
          label-for="salutation"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('salutation')"
        >
          <multiselect
            v-model="project.customer.salutation"
            id="salutation"
            :options="getEnumValues('Salutation')"
            :placeholder="$t('select_salutation')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">{{ $t(option) }}</template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('firstname')"
          label-for="firstname"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('firstname')"
        >
          <b-form-input
            :state="requirementsValidState('firstname')"
            v-model="project.customer.firstname"
            type="text"
            :placeholder="$t('firstname')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('lastname')"
          label-for="lastname"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('lastname')"
        >
          <b-form-input
            v-model="project.customer.lastname"
            type="text"
            :placeholder="$t('lastname')"
            :state="requirementsValidState('lastname')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('street')"
          label-for="street"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('street')"
        >
          <b-form-input
            v-model="project.customer.street"
            type="text"
            :placeholder="$t('street')"
            :state="requirementsValidState('street')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('streetNumber')"
          label-for="streetNumber"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.streetNumber"
            type="text"
            :placeholder="$t('streetNumber')"
          />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('streetNumberSuffix')"
          label-for="streetNumberSuffix"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.streetNumberSuffix"
            type="text"
            :placeholder="$t('streetNumberSuffix')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('zip')"
          label-for="zip"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('zip')"
        >
          <b-form-input
            v-model="project.customer.zip"
            type="text"
            :placeholder="$t('zip')"
            :state="requirementsValidState('zip')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('city')"
          label-for="city"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('city')"
        >
          <b-form-input
            v-model="project.customer.city"
            type="text"
            :placeholder="$t('city')"
            :state="requirementsValidState('city')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('cityPart')"
          label-for="cityPart"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.cityPart"
            type="text"
            :placeholder="$t('cityPart')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('email')"
          label-for="email"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('email')"
        >
          <b-form-input
            v-model="project.customer.email"
            type="text"
            :placeholder="$t('email')"
            :state="requirementsValidState('email')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('phone')"
          label-for="phone"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('phone')"
        >
          <b-form-input
            v-model="project.customer.phone"
            type="text"
            :placeholder="$t('phone')"
            :state="requirementsValidState('phone')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('mobile')"
          label-for="mobile"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.mobile" type="text" :placeholder="$t('mobile')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('birthdayAt')"
          label-for="birthdayAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="birthdayAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="1930:2021"
          >
          </Calendar>
        </b-form-group>
        <legend>
          <strong>{{ $t('GENERAL_CUSTOMER_INFORMATION') }}</strong>
        </legend>
        <b-form-group horizontal breakpoint="md">
          <ckeditor
            :editor="editor"
            v-model="project.sales.notes"
            :config="editorConfig"
          ></ckeditor>
        </b-form-group>
      </b-col>

      <b-col lg="5" md="5" sm="12">
        <legend>
          <strong>Haushaltsinfos</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('package')"
          label-for="package"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.sales.package" type="text" :placeholder="$t('package')" />
        </b-form-group>
        <!-- <b-form-group
          horizontal
          :label="$t('phase')"
          label-for="Phase"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('phase')"
            v-model="project.sales.phase"
            id="salesPhase"
            :options="getEnumValues('SalesPhase')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="salesPhaseColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="salesPhaseColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
          </multiselect>
        </b-form-group> -->
        <!-- <b-form-group
          horizontal
          :label="$t('state')"
          label-for="Phase"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('phase')"
            v-model="project.sales.state"
            id="salesPhase"
            :options="currentStateOptions"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="salesPhaseColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="salesPhaseColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
          </multiselect>
        </b-form-group> -->
        <!-- <b-form-group
          horizontal
          :label="$t('inCharge')"
          label-for="inCharge"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            v-model="project.sales.inCharge"
            :options="users"
            :custom-label="(user) => user.firstname + ' ' + user.lastname"
            :placeholder="$t('inCharge')"
            track-by="id"
            label="name"
            :allow-empty="true"
          >
          </multiselect>
        </b-form-group> -->
        <b-form-group
          horizontal
          :label="$t('kwhprice')"
          label-for="kwhprice"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.sales.kwhprice"
            type="text"
            :placeholder="$t('kwhprice')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('PERSONS_IN_HOUSEHOLD')"
          label-for="$t('PERSONS_IN_HOUSEHOLD')"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.sales.householdMembers"
            type="text"
            :placeholder="$t('PERSONS_IN_HOUSEHOLD')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('NUMBER_OF_METERS')"
          label-for="$t('NUMBER_OF_METERS')"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.sales.electricMeter"
            type="text"
            :placeholder="$t('NUMBER_OF_METERS')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('ELECTRICITY_COSTS_PER_YEAR')"
          label-for="$t('ELECTRICITY_COSTS_PER_YEAR')"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.sales.yearlyExpense"
            type="text"
            :placeholder="$t('ELECTRICITY_COSTS_PER_YEAR')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('usage')"
          label-for="usage"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.sales.usage" type="text" :placeholder="$t('usage')" />
        </b-form-group>
        <!-- <b-form-group
          horizontal
          :label="$t('callState')"
          label-for="callState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('callState')"
            v-model="project.sales.callState"
            id="callState"
            :options="getEnumValues('SalesCallState')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <b-badge :variant="callStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="callStateColor(option)">
                {{ $t(option) }}
              </b-badge>
            </template>
          </multiselect>
        </b-form-group> -->
        <legend>
          <strong>{{ $t('DEMAND_ANALYSIS_NOTES') }}</strong>
        </legend>
        <b-form-group horizontal breakpoint="md">
          <ckeditor
            :editor="editor"
            v-model="project.sales.notesAnalysis"
            :config="editorConfig"
          ></ckeditor>
        </b-form-group>

        <legend>
          <strong>{{ $t('INSTALLATION_NOTES') }}</strong>
        </legend>
        <b-form-group horizontal breakpoint="md">
          <ckeditor
            :editor="editor"
            v-model="project.sales.notesAssembly"
            :config="editorConfigInstallationNotes"
          ></ckeditor>
        </b-form-group>
      </b-col>

      <b-col v-if="false" lg="6" md="6" sm="12">
        <legend>
          <strong>Abschluss</strong>
        </legend>
      </b-col>

      <b-col v-if="false" lg="6" md="6" sm="12">
        <legend>
          <strong>Abschluss</strong>
        </legend>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import Calendar from 'primevue/calendar';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
// import Textarea from 'primevue/textarea';

import {
  getYesNoStateColor,
  getDocumentStateColor,
  getProjectStateColor,
  getSalesPhaseColor,
  getCallStateColor,
} from '@/helpers/colors';

export default {
  components: {
    Multiselect,
    // Textarea,

    Calendar,
    ckeditor: CKEditor.component,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      editor: InlineEditor,
      editorConfig: {
        allowedContent: false,
        forcePasteAsPlainText: true,
      },
      editorConfigInstallationNotes: {
        ...this.editorConfig,
        height: '30px',
      },

      users: [],
      currentStateOptions: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['getEnumValues', 'getClients', 'getEmployers', 'getUsers']),
    employers() {
      return this.getEmployers.filter((employer) => {
        if (
          employer.client &&
          this.project.client &&
          employer.client.id === this.project.client.id
        ) {
          return employer;
        }
      });
    },

    birthdayAt: {
      get() {
        return this.$calendarFormat(this.project.customer.birthdayAt);
      },
      set(value) {
        this.project.customer.birthdayAt = value;
      },
    },
    clients() {
      return this.isClient ? [this.getCurrentUser.client] : this.getClients;
    },
    deliveryAt: {
      get() {
        return this.$calendarFormat(this.project.deliveryAt);
      },
      set(value) {
        this.project.deliveryAt = value;
      },
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchClients',
      'fetchEmployersByClient',
      'fetchEmployers',
      'fetchUsers',
    ]),
    async updateStateOptions(phase) {
      let enumType;
      switch (phase) {
        case 'SALES_PHASE_LEAD':
          enumType = 'LeadState';
          break;
        case 'SALES_PHASE_QUALIFICATION':
          enumType = 'QualificationState';
          break;
        case 'SALES_PHASE_PLANNING':
          enumType = 'PlanningState';
          break;
        case 'SALES_PHASE_SALESCALL_1':
          enumType = 'SalesCall1State';
          break;
        case 'SALES_PHASE_TECHNICAL_INSPECTION':
          enumType = 'TechnicalInspectionState';
          break;
        case 'SALES_PHASE_SALESCALL_2':
          enumType = 'SalesCall2State';
          break;
        case 'SALES_PHASE_SOLD':
          enumType = 'SoldState';
          break;
        case 'SALES_PHASE_LOST':
          enumType = null;
          break;
        default:
          enumType = null;
      }

      if (enumType) {
        this.currentStateOptions = this.getEnumValues(enumType);
      } else {
        this.currentStateOptions = [];
      }
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    documentStateColor(state) {
      return getDocumentStateColor(state);
    },
    projectStateColor(state) {
      return getProjectStateColor(state);
    },
    salesPhaseColor(state) {
      return getSalesPhaseColor(state);
    },
    callStateColor(state) {
      return getCallStateColor(state);
    },
    async setup() {
      if (this.isAdmin) {
        await this.fetchClients();
        await this.fetchEmployers();
      }
      if (this.isClient) {
        await this.fetchEmployersByClient(this.getCurrentUser.client.id);
      }
      await this.fetchEnumValues('ProjectState');
      await this.fetchEnumValues('DocumentState');
      await this.fetchEnumValues('SalesPhase');
      await this.fetchEnumValues('SalesCallState');
      await this.fetchEnumValues('LeadState');
      await this.fetchEnumValues('QualificationState');
      await this.fetchEnumValues('QualificationState');
      await this.fetchEnumValues('PlanningState');
      await this.fetchEnumValues('SalesCall1State');
      await this.fetchEnumValues('TechnicalInspectionState');
      await this.fetchEnumValues('SalesCall2State');
      await this.fetchEnumValues('SoldState');
    },

    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
  },
  async mounted() {
    await this.setup();
    await this.fetchUsers({
      page: 0,
      pageSize: 100,
      sortField: 'lastname',
      sortOrder: 1,
      filters: {},
    });
    if (this.getUsers) {
      this.users = this.getUsers;
    }
  },
};
</script>
