<template>
  <b-card :title="$t('overview')" class="mb-4">
    <b-card-text class="overview-card-grid-container">
      <div>{{ $t('name') }}</div>
      <div>{{ project.customer.lastname }}, {{ project.customer.firstname }}</div>

      <div>{{ $t('address') }}</div>
      <div>
        <a
          :href="
            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              project.customer.street +
                ' ' +
                project.customer.streetNumber +
                project.customer.streetNumberSuffix +
                ', ' +
                project.customer.zip +
                ' ' +
                project.customer.city
            )}`
          "
          target="_blank"
        >
          {{ project.customer.street }} {{ project.customer.streetNumber }}
          {{ project.customer.streetNumberSuffix }}, {{ project.customer.zip }}
          {{ project.customer.city }}
        </a>
      </div>

      <div>{{ $t('phone') }}</div>
      <div v-if="project.customer.phone">
        <a @click.prevent="startTeamsCall(project.customer.phone)" href="#">
          {{ project.customer.phone }}
        </a>
      </div>

      <div>#</div>
      <div>{{ projectNumber }}</div>
    </b-card-text>
  </b-card>
</template>

<script>
import { generateGoogleMapsLink, startTeamsCall } from '@/helpers/integration';
export default {
  props: {
    project: { type: Object },
    projectNumber: null,
  },
  methods: {
    generateGoogleMapsLink,
    startTeamsCall,
  },
};
</script>

<style scoped lang="scss">
.overview-card-grid-container {
  display: grid;
  grid-template-areas:
    'name-label name-info'
    'address-label address-info'
    'phone-label phone-info'
    'number-label number-info';
  row-gap: 0.5em;
  column-gap: 1em; // Definiert den Abstand zwischen den Spalten
}

.name-label {
  grid-area: name-label;
}
.name-info {
  grid-area: name-info;
}

.address-label {
  grid-area: address-label;
}
.address-info {
  grid-area: address-info;
}

.phone-label {
  grid-area: phone-label;
}
.phone-info {
  grid-area: phone-info;
}

.number-label {
  grid-area: number-label;
}
.number-info {
  grid-area: number-info;
}
</style>
